<template>
  <div class="bookmaster-wraper">
    <app-modal-confirm
      ref="apikey_modal_delete"
      :modal-header-title="$t('common_vn.delete confirm title')"
      @confirmed="deleteApiKey()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common_vn.delete confirm") }}</p>
      </template>
    </app-modal-confirm>

    <app-modal-confirm
      ref="restaurant_modal_delete"
      :modal-header-title="$t('common_vn.delete confirm title')"
      @confirmed="deleteRestaurant()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common_vn.delete confirm") }}</p>
      </template>
    </app-modal-confirm>

    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >

      <form class="form-horizontal">
        <div class="row">
          <div class="col-12">
            <div class="card card-body">
              <template v-if="idEditing">
                <div class="form-group row">
                  <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.id") }}</label>
                  <label class="col-xl-1 col-lg-2 col-sm-2 m-t-8">{{ entry.id }}</label>
                </div>
              </template>

              <!-- Restaurant slug -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("restaurant.slug") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="restaurant_slug"
                    type="string128"
                    rules="required"
                    v-model="entry.slug"
                  />
                </div>
              </div>

              <!-- Restaurant name -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("restaurant.name") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="restaurant_name"
                    type="string128"
                    rules="required"
                    v-model="entry.name"
                  />
                </div>
              </div>

              <!-- Address VN -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("restaurant.address_vn") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-textarea
                    name="restaurant_address_vn"
                    type="string128"
                    rules="required"
                    v-model="entry.address_vn"
                  />
                </div>
              </div>

              <!-- Address JP -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("restaurant.address") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-textarea
                    name="restaurant_address"
                    type="string128"
                    rules="required"
                    v-model="entry.address"
                  />
                </div>
              </div>

              <!-- Hotline -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("restaurant.hotline") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="restaurant_hotline"
                    type="phone"
                    rules="required|phone_number"
                    v-model="entry.hotline"
                    placeholder="00000000000"
                  />
                </div>
              </div>

              <!-- Bank name -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("restaurant.bank_name") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="restaurant_bank_name"
                    type="string128"
                    rules="required"
                    v-model="entry.bank_name"
                  />
                </div>
              </div>

              <!-- Bank number -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("restaurant.bank_number") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="restaurant_bank_number"
                    type="digits"
                    rules="required"
                    v-model="entry.bank_number"
                  />
                </div>
              </div>

              <!-- Bank account name -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("restaurant.bank_account_name") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="restaurant_bank_account_name"
                    type="string128"
                    rules="required"
                    v-model="entry.bank_account_name"
                  />
                </div>
              </div>

              <!-- logo -->
              <div class="form-group row ">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("restaurant.logo") }}<span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-single-file-input accept="image/jpeg, image/png, image/jpg" name="restaurant_logo"
                    :imageSrc="entry.image_path"
                    @input="handlerUploadImage"
                    @updated_at="entry.data_updated_at = $event"
                    :id-editing="idEditing"
                    rules="required"
                    :allowedExtension="allowedExtension" >
                  </app-single-file-input>
                </div>
              </div>

              <!-- qr -->
              <div class="form-group row ">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("restaurant.qr") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-single-file-input accept="image/jpeg, image/png, image/jpg" name="restaurant_qr"
                    :imageSrc="entry.qr_url"
                    @input="handlerUploadQrImage"
                    :id-editing="idEditing"
                    :allowedExtension="allowedExtension" >
                  </app-single-file-input>
                </div>
              </div>

              <div class="card-footer row justify-content-end">
                <button type="button" :disabled="!valid && validated" class="btn btn-default m-r-10" @click="confirmCancel">
                  {{ $t('common_vn.cancel') }}
                </button>
                <button v-if="idEditing" type="button" :disabled="!valid && validated" class="btn btn-danger m-r-10" @click="handleDelete">
                  {{ $t('common_vn.delete') }}
                </button>
                <button v-if="idEditing" :disabled="!valid && validated" class="btn btn-primary m-r-10" @click="createOrUpdate" type="button">
                  {{  $t('common_vn.save') }}
                </button>
                <button v-else :disabled="!valid && validated" class="btn btn-primary m-r-10" type="button" @click="createOrUpdate" >
                  {{  $t('common_vn.create') }}
                </button>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import AppTextarea from "../../../components/forms/AppTextarea";
import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
import {ROUTES} from "@constants";
export default {
  name: "RestaurantCreateOrEdit",

  components: {
    AppTextarea,
    AppDateRangePicker,
  },

  data() {
    const id = this.$route.params.id
    return {
      entry: {
        id: null,
        slug: '',
        name: '',
        address_vn: '',
        address: '',
        hotline: '',
        bank_number: '',
        bank_number: '',
        bank_account_name: '',
        image_path: null,
        image: null,
        data_updated_at: null,
        qr_path: null,
        qr_url: null,
        is_new_qr: false,
      },
      apiKeyId: null,
      idEditing: id,
      entryNameUpdate: "",
      startValidate : !!id,
      allowedExtension : ['image/jpeg', 'image/png', 'image/jpg']
    };
  },
  watch: {
  },

  mounted: async function () {
    if (this.idEditing) {
      this.startValidate = false;
      let response = await this.$request.get(this.ENDPOINT.KYY_RESTAURANT_EDIT(this.idEditing));
      if (response.hasErrors()) {
        if (response.status === 'error'){
          this.__noticeError(this.$t(response.data.error_msg));
        }
        await this.redirectDefaultValue()
      } else {
        this.entry = _.cloneDeep(response.data.data);
        this.entry.path = 'menu_student/'
        this.entry.image_path = this.entry.image.url;
        this.startValidate = true;
        this.entry.is_new_qr = false;
      }
    }
  },

  methods: {
    handlerUploadImage(data) {
      this.entry.image = data
      this.entry.image_path = data
      this.entry.image_updated_at = new Date().getTime()
    },
    confirmCancel()
    {
      if(this.$store.state.setting.prevRouter.name === ROUTES.ADMIN.BOOK_MASTER_LIST) {
        this.__redirectBack()
      } else {
        this.redirectDefaultValue()
      }
    },
    async createOrUpdate() {
      this.startValidate = true;
      if (!await this.$refs.form_create_entry.validate()) {
        return;
      }
      let res = null;
      let msg = "";
      let params = _.cloneDeep(this.entry);
      if (this.idEditing) {
        res = await this.$request.patch(this.ENDPOINT.KYY_RESTAURANT_EDIT(this.idEditing), params);
        msg = this.$t("common.msg update ok")
      } else {
        res = await this.$request.post(this.ENDPOINT.KYY_RESTAURANT_CREATE, params);
        msg = this.$t("common.msg create ok")
      }
      if (res.hasErrors()) {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
      } else {
        this.__noticeSuccess(msg);
        await this.redirectDefaultValue();
      }
    },

    async redirectDefaultValue() {
      await this.$router.push({
        name: this.ROUTES.ADMIN.KYY_RESTAURANT_LIST, query: {}
      });
    },
    handleDelete() {
      this.$refs.restaurant_modal_delete.show();
    },
    async deleteRestaurant() {
      const res = await this.$request.delete(this.ENDPOINT.KYY_RESTAURANT_DELETE(this.idEditing));
      if (!res.hasErrors()) {
        this.__noticeSuccess(this.$t("common.msg delete ok"));
        await this.redirectDefaultValue();
      } else {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
        this.$refs.restaurant_modal_delete.hide();
      }
    },

    handlerUploadQrImage(data) {
      this.entry.qr_path = data
      this.entry.qr_url = data
      this.entry.is_new_qr = true
    },
  },
};
</script>
<style>
@media (min-width: 1200px) {
  .col-unit {
    flex: 0 0 14%;
  }
}

</style>
